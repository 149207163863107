.material-form {
  .react-tel-input .form-control {
    width: 100%;
    border-top: 1px;
    border-right: 1px;
    border-left: 1px;
  }

  .label {
    color: #646777;
    font-size: 12;
    line-height: 1;
    transform: translate(0, 1.5px) scale(0.85);
  }

  .react-tel-input .flag-dropdown{
    border: none;
    background: none;
  }

  .react-tel-input input:focus {
    outline: none;
    box-shadow: none;
  }

  .material-form__field {
    width: 100%;
    margin-bottom: 20px;

    &>div:before {

      @include themify($themes) {
        border-bottom-color: themed('colorFieldsBorder');
      }
    }

    &>div:hover:before {
      border-bottom: 1px solid $color-accent !important;
    }

    &>div:after {
      border-color: $color-accent;
    }

    input,
    textarea {
      font-size: 12px;

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    svg {

      @include themify($themes) {
        fill: themed('colorText');
      }
    }
  }

  .material-form__label {
    margin: 0;
    font-size: 12px;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  label {
    font-size: 12px;

    @include themify($themes) {
      color: themed('colorText');
    }

    &[data-shrink="true"] {
      transform: translate(0, 1.5px) scale(0.85);
    }
  }
}

.material-form__option {
  font-size: 13px !important;
  height: 18px !important;
}